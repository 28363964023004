<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <b-card
      :title="`${$t('Inventory')} ${$t('To Edit')}`"
    >

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>

            <!-- Field: Item -->
            <b-col
              cols="12"
              md="4"
            >
              <!-- Inventory Item Name -->
              <validation-provider
                #default="validationContext"
                name="item"
                rules="required"
              >
                <b-form-group
                  :label="$t('Item')"
                  label-for="item"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="inventoryData.item_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="itemOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="item"
                    @input="itemSelected"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>
            <!-- Field: Sale Price -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="sale-price"
                rules="required"
              >
                <b-form-group
                  :label="$t('Sale Price')"
                  label-for="sale-price"
                >
                  <b-form-input
                    id="sale-price"
                    v-model="inventoryData.sale_price"
                    :state="getValidationState(validationContext)"
                    type="number"
                    step="0.01"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Wholesale Price -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="wholesale-price"
                rules="required"
              >
                <b-form-group
                  :label="$t('Wholesale Price')"
                  label-for="wholesale-price"
                >
                  <b-form-input
                    id="wholesale-price"
                    v-model="inventoryData.wholesale_price"
                    :state="getValidationState(validationContext)"
                    type="number"
                    step="0.01"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Purchase Price -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="purchase-price"
                rules="required"
              >
                <b-form-group
                  :label="$t('Purchase Price')"
                  label-for="purchase-price"
                >
                  <b-form-input
                    id="purchase-price"
                    v-model="inventoryData.purchase_price"
                    :state="getValidationState(validationContext)"
                    type="number"
                    step="0.01"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Opening Quantity -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="opening-quantity"
                rules="required"
              >
                <b-form-group
                  :label="$t('Opening')"
                  label-for="opening-quantity"
                >
                  <b-form-input
                    id="opening-quantity"
                    v-model="inventoryData.opening_quantity"
                    :state="getValidationState(validationContext)"
                    type="number"
                    step="0.01"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Closing Quantity -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="closing-quantity"
                rules="required"
              >
                <b-form-group
                  :label="$t('Closing')"
                  label-for="closing-quantity"
                >
                  <b-form-input
                    id="closing-quantity"
                    v-model="inventoryData.closing_quantity"
                    :state="getValidationState(validationContext)"
                    type="number"
                    step="0.01"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Minimum Quantity -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="minimum-quantity"
                rules="required"
              >
                <b-form-group
                  :label="$t('Minimum')"
                  label-for="minimum-quantity"
                >
                  <b-form-input
                    id="minimum-quantity"
                    v-model="inventoryData.minimum_quantity"
                    :state="getValidationState(validationContext)"
                    type="number"
                    step="0.01"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Branch -->
            <b-col
              v-if="$can('delete', 'inventory')"
              cols="12"
              md="4"
            >
              <!-- Inventory Item Branch : only admin role can edit branch and only admin has 'delete' ability. other roles cannot see this -->
              <validation-provider
                #default="validationContext"
                name="branch"
                rules="required"
              >
                <b-form-group
                  :label="$t('Branch')"
                  label-for="branch"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="inventoryData.branch_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="branchOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="branch"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <feather-icon icon="SaveIcon" />&nbsp;
              {{ $t('Save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="cancel"
            >
              <feather-icon icon="XIcon" />&nbsp;
              {{ $t('Cancel') }}
            </b-button>
          </div>

        </b-form>

      </validation-observer>

    </b-card>
  </b-overlay>
</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from '@/libs/vue-select'
import store from '@/store'
import router from '@/router/index'
import OfflineDB from '@/libs/offline-db'
import storeModule from '../../../common/storeModule'
import inventoryStoreModule from '../inventoryStoreModule'

export default {
  components: {
    BOverlay,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {

  },
  data() {
    return {
      required,
      itemOptions: [],
      branchOptions: [],
      offlineDB: new OfflineDB(),
    }
  },
  mounted() {
    this.loadItems()
    this.loadBranches()
  },
  methods: {
    loadItems() {
      store
        .dispatch('common/fetchItems', { id_name: 1, price: 1 })
        .then(response => {
          const { items } = response.data.data
          this.offlineDB.items.clear()
          this.offlineDB.items.bulkAdd(items)
          for (let i = 0; i < items.length; i += 1) {
            this.itemOptions.push({ label: items[i].name, value: items[i].id })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadBranches() {
      store
        .dispatch('common/fetchBranches')
        .then(response => {
          const { branches } = response.data.data
          for (let i = 0; i < branches.length; i += 1) {
            this.branchOptions.push({ label: branches[i].name, value: branches[i].id })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    async itemSelected() {
      const selectedItem = await this.offlineDB.items.where({ id: this.inventoryData.item_id }).first()
      this.inventoryData.sale_price = selectedItem.sale_price
      this.inventoryData.wholesale_price = selectedItem.wholesale_price
      this.inventoryData.purchase_price = selectedItem.purchase_price
    },
    cancel() {
      this.$router.push({ name: 'inventory-list' })
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'inventory'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, inventoryStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const blankInventoryItemData = {
      item_id: null,
      opening_quantity: null,
      closing_quantity: null,
      minimum_quantity: null,
      branch_id: null,
    }

    const inventoryData = ref(JSON.parse(JSON.stringify(blankInventoryItemData)))
    const inventoryId = router.currentRoute.params.id
    store.dispatch('inventory/fetchOne', { id: inventoryId })
      .then(response => {
        const responseData = {
          id: response.data.data.id,
          item_id: response.data.data.item_id,
          sale_price: response.data.data.sale_price,
          wholesale_price: response.data.data.wholesale_price,
          purchase_price: response.data.data.purchase_price,
          opening_quantity: response.data.data.opening_quantity,
          closing_quantity: response.data.data.closing_quantity,
          minimum_quantity: response.data.data.minimum_quantity,
          branch_id: response.data.data.branch_id,
        }
        inventoryData.value = responseData
      })
      .catch(error => {
        if (error.response.status === 404) {
          inventoryData.value = undefined
        }
      })

    const onSubmit = () => {
      const data = JSON.parse(JSON.stringify(inventoryData.value))
      store.dispatch('inventory/edit', data)
        .then(response => {
          if (response.status === 200) {
            router.push({ name: 'inventory-list' })
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      inventoryData,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
